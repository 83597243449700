import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "impersonate-mode"
    }}>{`Impersonate Mode`}</h1>
    <p>{`Use this mode to browse the events calendar as an individual member. You'll see things as they see things! This is helpful for troubleshooting or to help members learn how to manage their own events, though it's only possible to impersonate members who have previously accessed the events calendar. Please remember to log out.`}</p>
    <h2 {...{
      "id": "step-by-step"
    }}>{`Step by step`}</h2>
    <ol>
      <li parentName="ol">{`When logged in as a staff member, click the "Impersonate" button on the lefthand navigation.`}</li>
      <li parentName="ol">{`Enter the cardholder's crendentials (e.g. library card number, username)`}</li>
      <li parentName="ol">{`You'll be taken to the same "My Bookings" page that the cardholder sees upon logging in, with the exception that you'll see a "Stop Impersonating" button at the top of the page.`}</li>
      <li parentName="ol">{`Navigate to the cardholder's Event Bookings or Room Bookings as needed. You may be on the phone with the cardholder or with them in person, which will allow you to explain what you're doing and confirm that they understand.`}</li>
      <li parentName="ol">{`You can view upcoming/past bookings, pay a fee via Stripe (if enabled) or cancel an upcoming booking.`}</li>
      <li parentName="ol">{`For past events, you may also leave a rating (select the number of stars out of 5) and provide written feedback about the event.`}</li>
      <li parentName="ol">{`Depending on your configuration, refunds may be processed automatically via Stripe. However, if offline payments are enabled, any pending refunds will also show here with instructions to visit the library.`}</li>
      <li parentName="ol">{`Please note that any changes you make on behalf of the cardholder will actually be processed by the system, so you may want to stop short of canceling a booking (for example) and let the cardholder do that themselves.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      